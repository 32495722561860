a {
    color: #4899d5;
    font-family: sans-serif, bold;
    font-weight: bold;
    font-size: 14px;
    text-decoration: none;
}

td {
    vertical-align: top;
}

.align-bottom {
    height: 60%;
    vertical-align: bottom;
}

.table-height {
    height: 100%;
}

.signature {
    height: 100%;
    max-width: 100%;
    white-space: nowrap;
    background: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
}

.blue-bold-text {
    color: #4899d5;
    font-family: sans-serif, bold;
    font-weight: bold;
    font-size: 14px;
}

.regular-text {
    color: #A2A8AB;
    font-family: sans-serif;
    font-size: 14px;
}

.main-image {
    width: 120px;
    height: 90px;
}

.light-mode .signature-table {
  background-color: #ffffff;
}

.dark-mode .signature-table {
  background-color: #333333;
}

.signature-container {
  padding: 0;
  margin: 0;
}

.grid-item-light-mode {
  background-color: #FFFFFF;
}

.grid-item-dark-mode {
  background-color: #333;
}

.font-color-light-mode {
  color: #333;
}

.font-color-dark-mode {
  color: #fff;
}

.horizontal-line {
  border: none;
  height: 1px;
  background-color: #F5F5F5;
  paddingTop: "0"
}

.google-style-logout {
  display: flex;
  align-items: center;
  position: fixed;
  top: 10px;
  right: 10px;
  background: white;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.google-style-logout {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #dadce0;
  border-radius: 8px; /* Change this to create a rounded square */
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  user-select: none
}

.google-style-logout-text {
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  color: #3c4043;
}

.google-signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
}
